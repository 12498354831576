/* -----------------------------------------------------------------------------
 | Workaround for hydration errors in production (React error #418, #423, #425)
 * -------------------------------------------------------------------------- */

// https://github.com/gatsbyjs/gatsby/discussions/17914

const ReactDOM = require('react-dom/client')

exports.replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
